import React, { useState, useEffect } from "react";
import { rtdb } from "../firebase";
import { ref, get, child } from "firebase/database";

const Announcement = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [announcementLive, setAnnouncementLive] = useState(false);
  const [announcementText, setAnnouncementText] = useState("No Announcement.");

  const getAnnouncementLive = async () => {
    const dbRef = ref(rtdb);
    get(child(dbRef, "somisomi/announcement/live"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setAnnouncementLive(snapshot.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAnnouncementText = async () => {
    const dbRef = ref(rtdb);
    get(child(dbRef, "somisomi/announcement/text"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setAnnouncementText(snapshot.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseAnnouncement = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    getAnnouncementLive();
    fetchAnnouncementText();
  }, []);

  useEffect(() => {
    if (announcementLive) {
      setIsVisible(true);
    }
  }, [announcementLive]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="relative flex items-center justify-center p-1 bg-purple_1 text-white font-montserrat lg:block hidden">
      <div className="flex flex-row items-center justify-center">
        {/* Left white dot */}
        <div className="relative w-1.5 h-1.5 mr-5 rounded-full bg-white"></div>

        <span className="text-center">{announcementText}</span>

        {/* Right white dot */}
        <div className="relative bg-white w-1.5 h-1.5 ml-5 rounded-full"></div>
      </div>

      {/* Close button */}
      <span
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xl"
        onClick={handleCloseAnnouncement}
      >
        <img className="h-4 cursor-pointer" src="/img/close.svg" alt="close" />
      </span>
    </div>
  );
};

export default Announcement;
