import React, { useRef, useState, useEffect } from "react";
import Title from "../components/Title";
// import Iframe from "react-iframe";
import IframeResizer from "iframe-resizer-react";

const FRANCHISE_APPLICATION_KEY =
  process.env.REACT_APP_FRANCHISE_APPLICATION_KEY;

const FranchiseInquiryForm = () => {
  const ref = useRef();
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");
  // useEffect(() => {
  //   //console.log("ref", ref.current);
  //   //setHeight(ref.current.clientHeight);
  // });

  return (
    <>
      <Title text="Franchise Application" />

      {isVerified ? (
        <div>
          <IframeResizer
            forwardRef={ref}
            style={{
              minWidth: "100%",
              border: "none",
              minHeight: "100%",
            }}
            src="https://form.123formbuilder.com/my-contact-form-4372656.html?customVars123=yes&hasEmbedFormStyle=1"
            heightCalculationMethod="max"
            scrolling="omit"
            checkOrigin={false} // Example additional option
          />
        </div>
      ) : (
        <div className="w-full p-10 font-montserrat bg-purple_3 flex flex-col items-center">
          <h1 className="font-bilagro text-purple_1 text-center lg:text-3xl md:text-3xl text-3xl mb-10">
            CONTENT PROTECTED
          </h1>
          <div className="lg:w-2/5 md:w-2/3 w-full">
            <div className="relative w-full bg-white rounded-xl border border-gray-300 px-3 py-1 mb-7">
              <label
                htmlFor="inputField"
                className="absolute top-2 left-3 text-xs text-gray-500"
              >
                Password
              </label>
              <input
                id="inputField"
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-white pt-5 pl-0 pr-3 pb-1 block w-full focus:outline-none focus:ring-0"
              />
            </div>
          </div>

          <div className="w-full flex justify-center">
            <button
              onClick={() => {
                //console.log(FRANCHISE_APPLICATION_KEY);
                if (password === FRANCHISE_APPLICATION_KEY) {
                  setIsVerified(true);
                } else {
                }
              }}
              className="w-32 px-4 py-3 mt-4 bg-purple_1 font-montserrat-semibold text-sm text-white rounded-full"
            >
              ENTER
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FranchiseInquiryForm;
