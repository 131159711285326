import React from "react";
import Title from "../components/Title";

const TermsOfUse = () => {
  return (
    <>
      <Title text="TERMS OF USE" />

      <div className="w-full flex items-center justify-center font-montserrat text-grey_1 mb-20">
        <div className="w-2/3 lg:text-base text-sm">
          Thank you for visiting somisomi.com. We have prepared the information
          below to ensure that your experience on our website (“Site”) is one
          you’ll want to repeat again and again. SomiSomi Franchise, Inc.
          provides this Site as a service to its customers. Please review the
          following basic rules that govern your use of our Site. By accessing
          this Site or by using any SomiSomi Services (defined below), you are
          agreeing to be bound by the following terms and conditions (the “Terms
          of Use” or “Terms”). Please note that your use of our Site constitutes
          your agreement to follow and be bound by these Terms. If you do not
          agree to these Terms, please do not use this Site. Although you may
          “bookmark” a particular portion of somisomi.com and thereby bypass
          this agreement, your use of this Site still binds you to the Terms.
          Since SomiSomi may revise this agreement at any time, you should visit
          this page periodically to review the Terms of your use. Should you
          have any questions concerning any of our policies, please contact us.
          <br />
          <br />
          The Service:
          <br />
          Subject to these Terms, SomiSomi grants to you a limited, personal,
          non-transferable, and non-exclusive right to use the services SomiSomi
          provides through this Site (the “Services”). Your use of the Services
          shall be strictly in accordance with these Terms and any applicable
          policies. Nothing in these Terms grants or transfers to you any
          ownership rights in the Service, including the software and other
          intellectual property rights related to the Service.
          <br />
          <br />
          Legal Information:
          <br />
          This Site is expressly owned and operated by SomiSomi. The mailing
          address for SomiSomi is 621 S. Western Ave., Suite 208-A, Los Angeles,
          CA 90005. Unless otherwise noted, all design and content featured on
          this Site—including navigational buttons and images, artwork,
          graphics, photography, text, and the like—are copyrights, trademarks,
          trade dress, and/or intellectual property that are owned, controlled,
          or licensed by SomiSomi. This Site in its entirety is protected by
          copyright and applicable trade dress. All worldwide rights, titles,
          and interests are reserved. The contents of our Site and the Site as a
          whole are intended solely for your personal, noncommercial use. ANY
          USE OF OUR SITE AND ITS CONTENT FOR PURPOSES OTHER THAN PERSONAL AND
          NONCOMMERCIAL IS PROHIBITED WITHOUT THE PRIOR WRITTEN PERMISSION OF
          SOMISOMI. Do not reproduce, publish, display, modify, sell, or
          distribute any of the materials from SomiSomi. You may, however,
          download or electronically copy and print any of the page contents
          displayed on the site, but please remember that these are available
          for your personal, noncommercial use only. Should you choose to
          download, copy, or forward any site materials via email, no right,
          title, or interest in those materials will be transferred to you.
          <br />
          <br />
          Privacy:
          <br />
          Your use of the Site or Services is subject to the SomiSomi Privacy
          Policy. By accessing or using the Site or Services, you understand and
          agree that your use of the Services, including information transmitted
          to or stored by SomiSomi, is governed by our Privacy Policy. and that
          SomiSomi may collect and retain personal or other information about
          you or the device you use to access the Site or Services.
          <br />
          <br />
          Third Party Services:
          <br />
          <ol className="list-decimal pl-10">
            <li>
              SomiSomi may from time to time recommend, provide you with access
              to, or enable third party software, applications, products,
              services or website links (collectively, “Third Party Services”)
              for your consideration or use. Such Third Party Services are made
              available only as a convenience, and your purchase, access or use
              of any such Third Party Services is solely between you and the
              applicable third party services provider (“Third Party Provider”).
              In addition to these Terms of Use, you also agree to be bound by
              the additional service-specific terms applicable to services you
              purchase from, or that are provided by, Third Party Providers.
            </li>
            <li>
              Any use by you of Third Party Services offered through the
              Services or the Site is entirely at your own risk and discretion,
              and it is your responsibility to read the terms and conditions
              and/or privacy policies applicable to such Third Party Services
              before using them.
            </li>
            <li>
              We do not provide any warranties or make representations to you
              with respect to Third Party Services. You acknowledge that
              SomiSomi has no control over Third Party Services and shall not be
              responsible or liable to you or anyone else for such Third Party
              Services. The availability of Third Party Services on SomiSomi’s
              Site or the integration or enabling of such Third Party Services
              with the Services does not constitute or imply an endorsement,
              authorization, sponsorship, or affiliation by or with SomiSomi.
              SomiSomi does not guarantee the availability of Third Party
              Services and you acknowledge that SomiSomi may disable access to
              any Third Party Services at any time in its sole discretion and
              without notice to you. SomiSomi is not responsible or liable to
              anyone for discontinuation or suspension of access to, or
              disablement of, any Third Party Service. SomiSomi strongly
              recommends that you seek specialist advice before using or relying
              on Third Party Services, to ensure they will meet your needs.
            </li>
            <li>
              If you install or enable a Third Party Service for use with the
              Services, you grant us permission to allow the applicable Third
              Party Provider to access your data and other Materials and to take
              any other actions as required for the interoperation of the Third
              Party Service with the Services, and any exchange of data or other
              Materials or other interaction between you and the Third Party
              Provider is solely between you and such Third Party Provider.
              SomiSomi is not responsible for any disclosure, modification or
              deletion of your data or other Materials, or for any corresponding
              losses or damages you may suffer, as a result of access by a Third
              Party Service or a Third Party Provider to your data or other
              Materials.
            </li>
            <li>
              Under no circumstances shall SomiSomi be liable for any direct,
              indirect, incidental, special, consequential, punitive,
              extraordinary, exemplary or other damages whatsoever, that result
              from any Third Party Services or your contractual relationship
              with any Third Party Provider. These limitations shall apply even
              if SomiSomi has been advised of the possibility of such damages.
              The foregoing limitations shall apply to the fullest extent
              permitted by Applicable Laws (defined below).
            </li>
            <li>
              You agree to indemnify and hold us and (as applicable) our parent,
              subsidiaries, affiliates, business partners, officers, directors,
              agents, employees, and suppliers harmless from any claim or
              demand, including reasonable attorneys’ fees, arising out of your
              use of a Third Party Service or your relationship with a Third
              Party Provider.
            </li>
          </ol>
          <br />
          Responsibility for your Content:
          <br />
          You are solely responsible for all content that you upload, post,
          email or otherwise transmit via or to the Sites, including the
          submission of product ratings and reviews and all other data, profile
          information, documents, text, software, applications, music, sound,
          photographs, graphics, video, messages, forum postings, comments,
          questions, answers or other materials (collectively, "Content"), if
          any part of the Site allows you to upload, post, email or otherwise
          transmit such Content of yours. Under no circumstances will SomiSomi
          be liable in any way for any content posted by third parties or at the
          direction of users, including, but not limited to, for any errors or
          omissions in any content, or for any loss or damage of any kind
          incurred as a result of the use of any content posted, emailed or
          otherwise transmitted via the Service. You acknowledge that SomiSomi
          does not pre-screen content, but that SomiSomi and its designees shall
          have the right (but not the obligation) in their sole discretion to
          refuse or remove any content that is available via the Service.
          <br />
          <br />
          Color Information:
          <br />
          While SomiSomi has tried to accurately display the colors of products,
          the actual colors you see will depend on your monitor and may not be
          accurate.
          <br />
          <br />
          Inaccuracies:
          <br />
          We endeavor to present the most recent, most accurate, and most
          reliable information on our Site at all times. However, there may be
          occasions when some of the information featured on this Site may
          contain incomplete data, typographical errors, or inaccuracies. Any
          errors are wholly unintentional and we apologize if erroneous
          information is reflected in merchandise price, item availability, or
          in any way affects your individual order. Please be aware that we
          present our content “as is” and make no claims to its accuracy, either
          expressed or implied. We reserve the right to amend errors or to
          update product information at any time without prior notice. In the
          event a SomiSomi product is listed at an incorrect price due to
          photographical error, typographical error or error in pricing
          information from our suppliers, SomiSomi shall have the right to
          refuse or cancel any orders placed for product listed at the incorrect
          price. SomiSomi Franchise, Inc. shall have the right to refuse or
          cancel any such orders whether or not the order has been confirmed and
          your credit card charged. If your credit card has already been charged
          for the purchase and your order is cancelled, SomiSomi shall issue a
          credit to your credit card account in the amount of the incorrect
          price.
          <br />
          <br />
          Confidential Information:
          <br />
          Please note that any information or material sent to SomiSomi through
          the Site will be deemed NOT to be confidential. By sending SomiSomi
          any information or material, you grant SomiSomi an unrestricted,
          irrevocable, world-wide, royalty free license to use, reproduce,
          display, perform, modify, transmit, and distribute those materials or
          information, and you also agree that SomiSomi is free to use any
          ideas, concepts, know-how, or techniques that you send us for any
          purpose.
          <br />
          <br />
          Denial of Access:
          <br />
          These Terms constitute an agreement that is effective unless and until
          terminated by SomiSomi. If in SomiSomi sole discretion you fail to
          comply with any term or provision of this agreement, SomiSomi may deny
          you access to the Site. In the event of denial of access by SomiSomi,
          you are no longer authorized to access the Site, and the restrictions
          imposed upon you with respect to material copied or downloaded, and
          the disclaimers and limitations of liabilities set forth in these
          terms and conditions, shall continue in force. These Terms constitute
          the entire agreement between you and SomiSomi relating to the subject
          matter addressed herein.
          <br />
          <br />
          Disclaimers & Limitation of Liability:
          <br />
          THE SERVICES, INCLUDING THE SITE (INCLUDING ALL CONTENT,
          FUNCTIONALITY, AND MATERIALS), ARE OFFERED BY SOMISOMI ON AN “AS IS,”
          “AS AVAILABLE,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS, WITH NO
          WARRANTY OF ANY KIND—WHETHER EXPRESS, IMPLIED, OR STATUTORY—INCLUDING,
          BUT NOT LIMITED TO, WARRANTIES OF TITLE OR THE IMPLIED WARRANTIES OF
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
          NONINFRINGEMENT. THIS DOES NOT AFFECT THOSE WARRANTIES WHICH ARE
          INCAPABLE OF EXCLUSION, RESTRICTION, OR MODIFICATION UNDER THE LAWS
          APPLICABLE TO THESE TERMS OF USE. Price and availability information
          is subject to change without notice.
          <br />
          <br />
          YOU ACKNOWLEDGE THAT NEITHER SOMISOMI, ITS AFFILIATES, PARENT, DIRECT
          OR INDIRECT SUBSIDIARIES, NOR ANY OF THEIR RESPECTIVE EMPLOYEES,
          AGENTS, THIRD PARTY PROVIDERS, OR LICENSORS WARRANT THAT THE SERVICES
          WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS
          TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES, OR AS TO
          THE TIMELINESS, SEQUENCE, ACCURACY, RELIABILITY, COMPLETENESS, OR
          CONTENT OF ANY INFORMATION, SERVICE, OR PRODUCT PROVIDED THROUGH THE
          SERVICES. SOMISOMI DOES NOT ENDORSE PRODUCTS OR SERVICES APPEARING ON
          LINKED SITES OR PURCHASED VIA LINKED SITES.
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT
          SHALL SOMISOMI BE RESPONSIBLE FOR ANY LOSS OF ANY KIND, INCLUDING LOSS
          ASSOCIATED WITH ANY COMPUTER VIRUSES WHICH MAY INFECT YOUR COMPUTER
          SYSTEM OR MOBILE DEVICE, PROPERTY DAMAGE, AND BODILY INJURY, WHETHER
          CAUSED BY ACCESS TO OR USE OF THE SERVICES OR THE SITE. TO THE MAXIMUM
          EXTENT PERMISSIBLE UNDER APPLICABLE LAW, IN NO EVENT SHALL SOMISOMI BE
          RESPONSIBLE TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FOR ANY
          DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE,
          EXEMPLARY, LOST PROFITS, ECONOMIC OR OTHER DAMAGES ARISING IN ANY WAY
          OUT OF THE INSTALLATION OR USE (OR INABILITY TO USE) OF THE SERVICES,
          THE SITE, ANY ONLINE SERVICES OR ANY INTERNET BROWSER SOFTWARE AND
          REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, WARRANTY, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE.
          <br />
          <br />
          Indemnification:
          <br />
          You agree to defend, indemnify, and hold harmless SomiSomi (and all of
          its affiliates, parent, direct and indirect subsidiaries) and each of
          their respective directors, officers, employees, agents, successors,
          and assigns from and against all losses, liabilities, damages, claims
          and expenses, including attorneys’ fees, arising out of, relating to,
          or resulting from your violation of these Terms of Use or misuse of
          the Services or the Site, including such violation or misuses
          conducted by your employee or agent. You agree to indemnify and hold
          harmless (and all of its affiliates, parent, direct and indirect
          subsidiaries) and each of their respective directors, officers,
          employees, agents, successors, and assigns from and against any and
          all claims, demands, actions, causes of action, suits, proceedings,
          losses, damages, costs, and expenses, including reasonable attorneys’
          fees and fees attributable to in house legal personnel, arising from
          or relating to your provision, or an end user’s use, of your Content,
          or any act, error, or omission of you in connection therewith,
          including but not limited to matters relating to incorrect,
          incomplete, or misleading information; libel; invasion of privacy;
          infringement of a copyright, trade name, trademark, service mark, or
          other intellectual property or other right; or violation of any
          applicable law.
          <br />
          <br />
          Choice of Law and Venue:
          <br />
          These terms and conditions and any applicable policies are entered
          into in the State of California and shall be governed by and construed
          in accordance with the laws of the State of California, exclusive of
          its choice of law rules. Each party to these terms and conditions and
          any applicable policies submits to the exclusive jurisdiction of the
          state and federal courts sitting in the County of Los Angeles in the
          State of California, and waives any jurisdictional, venue, or
          inconvenient forum objections to such courts. In any action to enforce
          these terms and any applicable policies, the prevailing party will be
          entitled to costs and attorney’s fees. In the event that any of the
          terms of these terms and conditions or any applicable policies are
          held by a court or other tribunal of competent jurisdiction to be
          unenforceable, those provisions shall be limited or eliminated to the
          minimum extent necessary so that these terms and conditions or
          applicable policies shall otherwise remain in full force and effect.
          <br />
          <br />
          General Terms:
          <br />
          Your rights and obligations under these Terms may not be assigned or
          transferred without written permission of SomiSomi. If any provision
          of these Terms is determined to be invalid, all other provisions will
          remain in force. Notice or other communication between you, and/or
          SomiSomi, may be given by conventional first-class mail or by e-mail
          and are effective on the date received.
          <br />
          <br />
          Your access to and use of the Services is subject to your continuing
          compliance with all of the following: (a) the Terms and Use; (b)
          SomiSomi’s Privacy Policy and other policies and notices to you by
          SomiSomi; (c) Third Party Service terms and conditions governing any
          content accessed through the Services that is published or distributed
          by a third-party website, and (d) Applicable Laws. “Applicable Laws”
          means all legislation, statutes, regulations, ordinances, rules,
          judgments, orders, decrees, rulings, and other requirements enacted,
          promulgated, or imposed by any governmental authority or judicial or
          regulatory body (including any self-regulatory body) at any level
          (e.g., municipal, county, provincial, state or national) that are
          applicable to or enforceable against a party or its personnel in
          relation to their activities under or pursuant to the Terms and Use.
          <br />
          <br />
          All the terms and provisions of the Terms of Use shall be binding upon
          and inure to the benefit of the parties to the Terms of Use and to
          their respective heirs, successors, permitted assigns and legal
          representatives. SomiSomi shall be permitted to assign these Terms of
          Use without notice to you or consent from you. You shall have no right
          to assign or otherwise transfer the Terms of Use, or any of your
          rights or obligations hereunder, to any third party without SomiSomi’s
          prior written consent, to be given or withheld in SomiSomi’s sole
          discretion.
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
