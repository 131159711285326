import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="relative bg-purple_1 pb-12 lg:mt-0 md:mt-10 mt-5 z-[100] font-montserrat">
      <div className="flex lg:flex-row flex-col justify-center mb-5">
        {/* LOGOS */}
        <div className="pt-24 lg:pr-32 lg:pb-24 flex flex-col items-center justify-center">
          {/* SOMISOMI Logo */}
          <img className="h-16 mb-10" src="/img/logo@2x.png" alt="logo" />

          {/* Social Media Logos */}
          <div className="flex flex-row">
            <div className="w-10 h-10 border-2 border-purple_3 hover:border-white cursor-pointer rounded-full flex items-center justify-center mr-2">
              <a href="https://www.yelp.com/biz/somisomi-los-angeles-11">
                <img className="h-4" src="/img/social-media-icons/yelp.png" alt="yelp" />
              </a>
            </div>
            <div className="w-10 h-10 border-2 border-purple_3 hover:border-white cursor-pointer rounded-full flex items-center justify-center mr-2">
              <a href="https://www.facebook.com/somisomiicecream/">
                <img className="h-4" src="/img/social-media-icons/fb.png" alt="fb" />
              </a>
            </div>
            <div className="w-10 h-10 border-2 border-purple_3 hover:border-white cursor-pointer rounded-full flex items-center justify-center mr-2">
              <a href="https://www.instagram.com/somisomiicecream/">
                <img className="h-4" src="/img/social-media-icons/ig.png" alt="ig" />
              </a>
            </div>
            <div className="w-10 h-10 border-2 border-purple_3 hover:border-white cursor-pointer rounded-full flex items-center justify-center">
              <a href="https://www.youtube.com/channel/UCLpyry5QP6gO_v20qoW8e2A">
                <img className="h-4" src="/img/social-media-icons/yt.png" alt="twt" />
              </a>
            </div>
          </div>
        </div>

        {/* NAV */}

        {/* EXPLORE */}
        <div className="lg:pt-24 pt-10 lg:mr-12 flex flex-col lg:text-left text-center lg:text-base text-xl">
          <span className="text-white lg:mb-2 md:mb-10 mb-5 font-bilagro lg:text-2xl text-3xl">EXPLORE</span>
          <div
            onClick={() => navigate("/story")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Our Story
          </div>
          <div
            onClick={() => navigate("/softserve")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Menu
          </div>
          <div
            onClick={() => navigate("/nutrition-facts")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Nutrition Facts
          </div>
          <div
            onClick={() => navigate("/allergen-info")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Allergen Info
          </div>
          <div
            onClick={() => navigate("/locations")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Locations
          </div>
          <div
            onClick={() => navigate("/lab-store")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Lab Store
          </div>
          <div
            onClick={() => navigate("/careers")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Careers
          </div>
          <div
            onClick={() => navigate("/customer-service")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Customer Service
          </div>
          <div
            onClick={() => navigate("/franchise-one")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Franchising Opportunity
          </div>
          <div
            onClick={() => navigate("/fundmi")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Fundraising Opportunity
          </div>
        </div>

        {/* SHOP */}
        <div className="lg:pt-24 pt-10 lg:mr-12 flex flex-col lg:text-left text-center lg:text-base text-xl">
          <div onClick={() => navigate("/shop")} className="text-white lg:mb-2 mb-5 font-bilagro lg:text-2xl text-3xl">
            SHOP
          </div>
          {/* DISABLED */}
          {/* <div
            onClick={() => navigate("/shop")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-6 cursor-pointer"
          >
            Shop Online
          </div> */}
          <a
            href="https://squareup.com/gift/VPWTW4B3FABB9/order"
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Gift Card
          </a>
        </div>

        {/* GET HELP */}
        <div className="md:pt-24 pt-10 lg:mr-12 flex flex-col lg:text-left text-center lg:text-base text-xl">
          <span className="text-white lg:mb-2 mb-5 font-bilagro lg:text-2xl text-3xl">GET HELP</span>
          <div onClick={() => navigate("/faq")} className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer">
            FAQ
          </div>
        </div>

        {/* CONNECT WITH US */}
        <div className="md:pt-24 pt-10 flex flex-col lg:text-left text-center lg:text-base text-xl">
          <span className="text-white lg:mb-2 mb-5 font-bilagro lg:text-2xl text-3xl">CONNECT WITH US</span>
          <div
            onClick={() => navigate("/privacy-policy")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Privacy Policy
          </div>
          <div
            onClick={() => navigate("/terms-of-use")}
            className="text-gray-300 hover:text-white lg:mb-2 mb-4 cursor-pointer"
          >
            Terms of Use
          </div>
          <div onClick={() => navigate("/ccpa-notice")} className="text-gray-300 lg:mb-2 mb-4 cursor-pointer">
            CCPA Notice
          </div>
          <div onClick={() => navigate("/ada")} className="text-gray-300 lg:mb-2 mb-4 cursor-pointer">
            ADA Compliance
          </div>
          <div onClick={() => navigate("/lab-survey")} className="text-gray-300 lg:mb-2 mb-10 cursor-pointer">
            Customer Survey
          </div>
        </div>
      </div>

      <span className="text-gray-300 flex justify-center">© 2024 SomiSomi | All rights reserved</span>
    </div>
  );
};

export default Footer;
