import React from "react";
import Title from "../components/Title";
import ContentCard from "../components/ContentCard";

const Story = () => {
  const stories = [
    {
      image: "/img/story/story-1.png",
      title: { text: "Who We Are", size: "lg:text-5xl md:text-3xl text-3xl" },
      description:
        "Somisomi is founded by 2 dessert enthusiasts whose quest for the perfect dessert led them to creating their very own brand. What started as a standalone ice cream and taiyaki store has now expanded to over 33 locations all over South California, Texas, Nevada and Washington. <br><br> Since we opened our doors in our first location we remain true to our original mission, to share bites of happiness and make dessert dreams come true for every customer. <br><br> Somisomi is powered by a team of professionals that is committed to creating delectable memories for every customer. Every one of you, our customers, inspire our desserts and that is why they are made from your imagination: a selection of rich soft-serve flavors— Milk, Chocolate, Ube, Matcha, Oreo, Milk Tea, and Swirl combinations— in the bite of taiyaki and finished with your choice of toppings. We hope you have fun designing your desserts with every visit, and we hope you enjoy your own bite of happiness, over and over again.",
      order: ["order-2", "order-1"],
      button: null,
    },
    {
      image: "/img/story/story-2.png",
      title: {
        text: "A Bite You Deserve",
        size: "lg:text-5xl md:text-3xl text-3xl",
      },
      description:
        "Eating a soft serve dessert is a joyous activity shared by kids and adults alike. SomiSomi deliver soft, pure ice cream with a creamy, rich density our fans love. All our soft serve is made with love - for our amazing customers!",
      order: ["md:order-1 order-2", "md:order-2 order-1"],
      button: null,
    },
    {
      image: "/img/story/story-3.png",
      title: {
        text: "Franchise Opportunity",
        size: "lg:text-5xl md:text-3xl text-3xl",
      },
      description:
        "Love SomiSomi and want to share it with the world? We are currently seeking ice cream enthusiasts who share the same vision with us.",
      order: ["order-2", "order-1"],
      button: { text: "VISIT PAGE", link: "/franchise-one" },
    },
  ];

  return (
    <>
      <Title text="OUR STORY" />

      <div className="w-full flex justify-center md:mb-20 mb-10">
        <video className="w-2/3 rounded-lg" controls autoPlay muted>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/somisomi-website.appspot.com/o/videos%2Fstory.mp4?alt=media&token=73fb6a67-0e41-429d-8d6a-3c0cacb4722e"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="w-full flex flex-col items-center justify-center font-montserrat text-purple_2">
        {stories.map((story, index) => (
          <ContentCard
            key={index}
            images={[{ src: story.image, alt: story.title }]}
            title={story.title}
            description={story.description}
            order={story.order}
            button={story.button}
          />
        ))}
      </div>
    </>
  );
};

export default Story;
