import Title from "../components/Title";
import FaqComponent from "../components/FaqComponent";
import IframeResizer from "iframe-resizer-react";

const Fundraising = () => {
  const faqs = [
    {
      q: "What is FundMi?",
      a: (
        <p>
          FundMi with SomiSomi is a partnership between organizations and our stores, intended to generate support for
          the local community. Parts of FundMi's sales will be returned to the organization, following agreed upon
          terms.
        </p>
      ),
    },
    {
      q: "Who can apply for the FundMi fundraising activity?",
      a: <p>Any non-profit organizations intended to support the community will be considered for FundMi.</p>,
    },
    {
      q: "What are the terms of the fundraiser? How much of the proceeds will my organization receive?",
      a: (
        <p>
          Full terms of FundMi will be shared with you, upon receipt of your request. You will have to confirm these
          terms to proceed with the FundMi activity.
        </p>
      ),
    },
    {
      q: "How long should the fundraising be?",
      a: <p>The fundraising activity is set only as a one-day event.</p>,
    },
    {
      q: "What help do you need from my organization? Can we prepare our own promotional materials?",
      a: (
        <p>
          Once the FundMi activity is confirmed, we highly encourage you to invite your supporters to join the cause.
          SomiSomi will provide digital flyers which you can circulate among your members and friends, so no need to
          create your own materials.
        </p>
      ),
    },
    {
      q: "Can we do on-site promotions?",
      a: (
        <p>
          This will be subject to approval. You can send the details to{" "}
          <a href="mailto:fundmi@somisomi.com">fundmi@somisomi.com</a> and our team will respond to your request.
        </p>
      ),
    },
    {
      q: "Can we bring in merchandise or other products to support the fundraising event?",
      a: (
        <p>
          Sale of third party merchandise is prohibited. We encourage everyone to channel their support through the
          FundMi activity instead.
        </p>
      ),
    },
    {
      q: "I'm not sure if I have enough supporters for my cause. Should I still submit a FundMi request?",
      a: (
        <p>
          We note, however, that fundraising terms shall apply, requiring the fundraiser to meet a minimum sales target,
          as stated above in the chart. This will also confirm the percentage of proceeds that will be given to your
          organization.
        </p>
      ),
    },
    {
      q: "My request has been accepted, but I now need to cancel my fundraiser. How do I proceed?",
      a: (
        <p>
          Get in touch with SomiSomi's Franchise Support Team and your partner's store via Hangouts, at least 5-7 days
          before the event date.
        </p>
      ),
    },
    {
      q: "My FundMi request has been submitted. Can I still extend the duration of my fundraiser?",
      a: <p>The fundraising activity is set only as a one-day event.</p>,
    },
    {
      q: "What are the terms of the fundraiser? How much of the proceeds will my organization receive?",
      a: <img src="/img/fundmi-conditions.jpg" alt="FundMi terms" />,
    },
    {
      q: "Is it possible to have the percentage of the fundraiser's sales share increased?",
      a: (
        <p>
          The fund rate condition chart that we have is standard across all fundraising activities in collaboration with
          SomiSomi.
        </p>
      ),
    },
  ];
  return (
    <>
      <Title text="FUNDMI WITH SOMISOMI" />

      <div className="md:w-full flex justify-center mb-20">
        <img className="sm:flex hidden w-full" src="/img/fundmi.png" alt="somisomi fundmi detail" />
        <img className="sm:hidden flex w-full" src="/img/fundmi-mobile.png" alt="somisomi fundmi detail" />
      </div>

      <IframeResizer
        style={{
          minWidth: "100%",
          border: "none",
          minHeight: "100%",
        }}
        src="https://form.123formbuilder.com/6130547/fundmi-application-form-2022?hasEmbedFormStyle=1"
        heightCalculationMethod="max"
        scrolling="omit"
        checkOrigin={false} // Example additional option
      />

      <div className="lg:p-14 p-10 lg:mb-14 mb-0 lg:mt-10 mt-5">
        <h1 className="lg:text-6xl md:text-6xl text-3xl font-bilagro text-purple_1 text-center">FAQ</h1>
      </div>

      <FaqComponent faqs={faqs} />
    </>
  );
};

export default Fundraising;
