import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import Footer from "../components/Footer";
import ContentCard from "../components/ContentCard";

const FranchiseOne = () => {
  const navigate = useNavigate();

  const contents = [
    {
      image: "/img/franchise/product.png",
      title: {
        text: "Unique Dessert Concept, picture of Ube Ahboong cone",
        size: "lg:text-3xl md:text-2xl text-3xl",
      },
      description:
        "SomiSomi offers a truly unique and visually appealing dessert concept with its Ah-Boong soft-serve ice cream filled fish-shaped cones. Not only this novelty factor captures the attention of customers, encouraging them to try something different from the standard ice cream options available in the market but also our delegate products make our customers become our fans. The distinctive presentation and delicious flavors have attracted a steady stream of curious and repeat customers to the franchise locations.",
      order: ["md:order-1 order-2", "md:order-2 order-1"],
      number: "01",
    },
    {
      image: "/img/franchise/brand.jpg",
      title: {
        text: "Proven Success & Brand Recognition",
        size: "lg:text-3xl md:text-2xl text-3xl",
      },
      description:
        "As a well-established franchise for years in operation since the first location in the heart of Los Angeles, SomiSomi has already demonstrated success in multiple locations throughout different states and gained brand recognition among dessert enthusiasts. Franchisees can benefit from joining a popular and respected brand, which can significantly reduce the time and effort required to build a customer base and establish trust with potential customers. With a recognizable brand, franchisees may also enjoy a more straightforward entry into the competitive dessert market.",
      order: ["order-2", "order-1"],
      number: "02",
    },
    {
      image: "/img/franchise/manager.png",
      title: {
        text: "Comprehensive Franchise Support",
        size: "lg:text-3xl md:text-2xl text-3xl",
      },
      description:
        "SomiSomi provides its franchisees with comprehensive support, including initial training, and ongoing operational assistance. Franchisees can benefit from the expertise and experience of the franchisor, enabling them to navigate challenges effectively and make informed business decisions. The support network provided by the franchise system can be invaluable for individuals who may not have prior experience in the food and beverage industry, increasing the likelihood of a successful and profitable venture.",
      order: ["md:order-1 order-2", "md:order-2 order-1"],
      number: "03",
    },
  ];

  return (
    <>
      <Title text="Why SOMISOMI?" />

      <div className="w-full flex flex-col items-center justify-center font-montserrat text-purple_2">
        {contents.map((content, index) => (
          <ContentCard
            key={index}
            images={[{ src: content.image, alt: "Why SOMISOMI?" }]}
            title={content.title}
            description={content.description}
            order={content.order}
            number={content.number}
          />
        ))}
      </div>

      <div className="bg-grad lg:p-14 md:p-20 p-10 lg:mb-14 mb-0 flex flex-col items-center justify-center">
        <h1 className="lg:text-6xl md:text-6xl text-3xl font-bilagro text-purple_1 text-center">
          WHO ARE WE LOOKING FOR?
        </h1>

        <button
          onClick={() => navigate("/franchise-two")}
          className="w-40 px-4 py-3 mt-10 bg-purple_1 font-montserrat-semibold text-white text-center text-sm rounded-full"
        >
          CONTINUE
        </button>
      </div>
    </>
  );
};

export default FranchiseOne;
